<template>
    <main class="">
        <section class="section-box">
            <div class="banner-hero banner-1 mt-10">

                <div class="row">
                    <div class="col-lg-8">
                        <div class="banner-big  mb-10"
                             style="background-image: url(assets/imgs/train.png); background-color: #90c395;">
                            <div class="row">
                                <div class="col-lg-12 col-md-7 col-sm-12">
                                    <p class="">

                                        <b>{{ $t('home.introHeader') }}</b>
                                        <br /><br />
                                        <span v-html="$t('home.introText')"></span>
                                        <br /><br />
                                        <router-link :to="{name: 'register'}">
                                            {{$t('home.introLink')}}
                                        </router-link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 d-none d-lg-block">
                        <div class="row">
                            <div class="col-lg-12 col-md-6 col-sm-12">
                                <div class="mapBanner bg-13" style=" background-image: url(/nederland.svg)">
                                    <h4 class="mb-10">{{ $t('home.mapBanner.nearby') }}</h4>
                                    <p class="color-brand-3 font-desc">{{ $t('home.mapBanner.adsNearby') }}</p>
                                    <div class="mt-20">
                                        <router-link :to="{name: 'maps'}" class="btn btn-buy btn-arrow-right">{{ $t('home.mapBanner.viewMap') }}</router-link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section-box">
            <div class="">
                <div class="row">
                    <div class="col-lg-12 mb-5">
                        <div class="border-bottom">
                            <h3>{{$t('global.items')}}</h3>

                        </div>
                        <p class="homeDescription mr-5">

                            {{$t('home.catalogDescription')}}

                        </p>
                    </div>
                    <div class="col-lg-12">
                        <div class="row test g-0">
                            <!--<div class="col-3"></div>-->
                            <div class="col-3">
                                <router-link :to="{name: 'items', params: { categoryId: 'fleischmann'}}" class="brandImage"><img src="assets/imgs/fleischmann.png"></router-link>
                            </div>
                            <div class="col-3">
                                <router-link :to="{name: 'items', params: { categoryId: 'marklin'}}" class="brandImage"><img src="assets/imgs/marklin.svg"></router-link>
                            </div>
                            <div class="col-3">
                                <router-link :to="{name: 'items', params: { categoryId: 'trix'}}" class="brandImage"><img src="assets/imgs/trix.png"></router-link>
                            </div>
                            <div class="col-3">
                                <router-link :to="{name: 'items', params: { categoryId: 'faller'}}" class="brandImage"><img src="assets/imgs/faller.svg"></router-link>
                            </div>
                            <div class="col-3">
                                <router-link :to="{name: 'items', params: { categoryId: 'lgb'}}" class="brandImage"><img src="assets/imgs/lgb.png"></router-link>
                            </div>
                            <div class="col-3">
                                <router-link :to="{name: 'items', params: { categoryId: 'roco'}}" class="brandImage"><img src="assets/imgs/roco.svg"></router-link>
                            </div>
                            <div class="col-3">
                                <router-link :to="{name: 'items', params: { categoryId: 'arnold'}}" class="brandImage"><img src="assets/imgs/arnold.svg"></router-link>
                            </div>
                            <div class="col-3">
                                <router-link :to="{name: 'items', params: { categoryId: 'brawa'}}" class="brandImage"><img src="assets/imgs/brawa.png"></router-link>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="row g-0 mt-10">
                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                        <div class="card-grid-style-2 card-grid-style-2-small hover-up categories">
                            <div class="image-box">
                                <router-link :to="{name: 'items', params: { categoryId: 'fleischmann'}}">
                                    <img src="assets/imgs/category/fleischmann.png">
                                </router-link>
                            </div>
                            <div class="info-right">
                                <router-link :to="{name: 'items', params: { categoryId: 'fleischmann'}}"
                                             class="color-brand-3 font-sm-bold">
                                    <h6> Fleischmann</h6>
                                </router-link>
                                <ul class="list-links-disc">
                                    <li>
                                        <router-link class="font-sm" :to="{name: 'items', params: { categoryId: ['fleischmann','h0']}}">H0 spoor</router-link>
                                        <ul style="margin-left:20px">
                                            <li>
                                                <router-link class="font-sm" :to="{name: 'items', params: { categoryId: ['fleischmann','h0', 'rails']}}">Rails</router-link>
                                            </li>
                                            <li>
                                                <router-link class="font-sm" :to="{name: 'items', params: { categoryId: ['fleischmann','h0', 'locomotieven']}}">Lokomotieven</router-link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <router-link class="font-sm" :to="{name: 'items', params: { categoryId: ['fleischmann','n']}}">N spoor</router-link>
                                    </li>
                                </ul>
                                <router-link class="btn btn-gray-abs" :to="{name: 'items', params: { categoryId: ['fleischmann']}}">
                                    {{$t('home.showAll')}}
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class=" col-lg-4 col-md-6 col-sm-12 col-12">
                        <div class="card-grid-style-2 card-grid-style-2-small hover-up categories">
                            <div class="image-box">
                                <router-link :to="{name: 'items', params: { categoryId: ['marklin']}}">
                                    <img src="assets/imgs/category/marklin.jpg">
                                </router-link>
                            </div>
                            <div class="info-right">
                                <router-link :to="{name: 'items', params: { categoryId: ['marklin']}}" class="color-brand-3 font-sm-bold">

                                    <h6>Marklin</h6>
                                </router-link>
                                <ul class="list-links-disc">
                                    <li>
                                        <router-link class="font-sm" :to="{name: 'items', params: { categoryId: ['marklin','h0']}}">H0 spoor</router-link>
                                        <ul style="margin-left:20px">
                                            <li>
                                                <router-link class="font-sm" :to="{name: 'items', params: { categoryId: ['marklin','h0', 'rails']}}">Rails</router-link>
                                            </li>
                                            <li>
                                                <router-link class="font-sm" :to="{name: 'items', params: { categoryId: ['marklin','h0', 'locomotieven']}}">Lokomotieven</router-link>
                                            </li>
                                            <li>
                                                <router-link class="font-sm" :to="{name: 'items', params: { categoryId: ['marklin','h0', 'rijtuigen']}}">Rijtuigen</router-link>
                                            </li>
                                        </ul>
                                        <router-link class="font-sm" :to="{name: 'items', params: { categoryId: ['marklin','z']}}">Z spoor</router-link>

                                    </li>
                                    <li>
                                    </li>
                                </ul>
                                <router-link class="btn btn-gray-abs" :to="{name: 'items', params: { categoryId: ['marklin']}}">
                                    {{$t('home.showAll')}}
                                </router-link>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                        <div class="card-grid-style-2 card-grid-style-2-small hover-up categories">
                            <div class="image-box">
                                <router-link class="font-sm" :to="{name: 'items', params: { categoryId: ['trix']}}">
                                    <img src="assets/imgs/category/trix.jpg">
                                </router-link>
                            </div>
                            <div class="info-right">
                                <router-link class="font-sm" :to="{name: 'items', params: { categoryId: ['trix']}}">
                                    <h6>Trix</h6>
                                </router-link>
                                <ul class="list-links-disc">
                                    <li>
                                        <router-link class="font-sm" :to="{name: 'items', params: { categoryId: ['trix','h0', 'rails']}}">H0 spoor</router-link>
                                    </li>
                                    <li>
                                        <router-link class="font-sm" :to="{name: 'items', params: { categoryId: ['trix','h0', 'lokomotieven']}}">Lokomotieven</router-link>
                                    </li>
                                    <li>
                                        <router-link class="font-sm" :to="{name: 'items', params: { categoryId: ['trix','h0', 'rijtuigen']}}">Rijtuigen</router-link>
                                    </li>
                                </ul>
                                <router-link class="btn btn-gray-abs" :to="{name: 'items', params: { categoryId: ['trix']}}">
                                    {{$t('home.showAll')}}

                                </router-link>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="row g-0">
                    <div class="col-12 col-md-6 mb-5">
                        <div class="mr-10">
                            <div class="border-bottom">
                                <h3>{{$t('global.inventory')}}</h3>

                            </div>
                            <div class="row g-0 homeDescription">
                                <div class="col-6">
                                    <div v-html="$t('home.inventoryDescription')">
                                    </div>
                                </div>
                                <div class="col-6 text-end">
                                    <img src="/screenshot1.png" height="300" />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-12 col-md-6">
                        <div class="mr-10">
                            <div class="border-bottom">
                                <h3>{{$t('global.listings')}}</h3>

                            </div>
                            <div class="mt-10">
                                <ListingsComponent limit="3" width="full" :show-navigation="false"></ListingsComponent>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>

</template>

<script>
    import ListingsComponent from "@/components/listingscomponent/listingscomponent.vue";

    export default {
        name: 'items',
        data() {
            return {
            }
        },
        props: {
            msg: String,
        },
        components: {
            ListingsComponent,
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .test > div > a {
        display: block;
        border: 1px solid #b7b7b7;
        border-radius: 4px;
        vertical-align: middle;
        padding-top: 20px;
        padding-bottom: 20px;
        margin: 5px 5px 0px 0px;
        text-align: center;
    }

        .test > div > a > img {
            height: 13px;
        }

    img {
        border-radius: 5px;
    }

    .homeDescription {
        border: 1px solid #b7b7b7;
        margin-top: 10px;
        padding: 10px;
        border-radius: 4px;
    }


</style>


