<template>
    <div class="">
        <div v-if="!showAds" class="mt-10 mb-10"></div>

        <div v-if="showAds">
            <div v-if="showTopBorder" class="border-bottom mt-10 mb-10 advertTop"></div>

            <div class="advertContainer">
                <div class="advert d-none d-md-block">
                    <ins class="adsbygoogle"
                         style="display:block"
                         data-ad-client="ca-pub-3164076105238110"
                         data-ad-slot="8346782629"
                         data-ad-format="auto"
                         data-full-width-responsive="true"></ins>
                </div>
                <div class="advert-mobile d-block d-sm-none">
                    <!--ad-->
                    <!--320x50-->
                    <!-- horizontal -->
                    <ins class="adsbygoogle"
                         style="display:block"
                         data-ad-client="ca-pub-3164076105238110"
                         data-ad-slot="8346782629"
                         data-ad-format="auto"
                         data-full-width-responsive="true"></ins>

                </div>
            </div>

            <div class="border-bottom mt-10 mb-10"></div>
        </div>
    </div>
</template>

<script>
    //import axios from 'axios'

    export default {

        name: 'AdvertComponent',
        data() {
            return {
                showAds: true,
            }
        },
        props: {
            showTopBorder: {
                type: Boolean,
                default: true
            }
        },

        methods: {

        },
        async mounted() {
            try {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            } catch (ex) { console.log(ex) }
        }
    }
</script>
<style scoped>
    .advertContainer {
        border: 1px solid #dadada;
        border-radius: 4px;
        text-align: center;
        padding: 10px;
    }

    .advert, .advert-mobile {
        border: 1px solid gray;
        border-radius: 4px;
    }

    .advert {
        border: 1px solid gray;
        width: 728px;
        height: 90px;
        margin-left: auto;
        margin-right: auto;
    }

    .advert-mobile {
        width: 320px;
        height: 50px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 17px;
    }
</style>