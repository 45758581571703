import httpClient from '@/httpClients/httpClient';
import AdvertComponent from '@/components/advert.vue';


export default {

    name: 'ListingsComponent',
    components: {
        AdvertComponent
    },
    props: {
        postCode: {
            type: Number
        },
        showNavigation: {
            type: Boolean,
            default: true
        },
        width: {
            type: String,
            default: "standard"
        },
        limit: {
            type: Number,
        }

    },
    data() {
        return {
            listings: Array,
            categoryTree: Array,
            activeCategory: {},
            categories: {},
            pagination: {
                page: 1,
                total: 0
            },
            showFiltersModal: false,
            filter: { rangeInKm: 500 },
            rangeSelector: 6,
            aggregates: {},
            filters: [],
            selectedCategory: "",
            showAsMgmnt: false,
            showFilterOptions: false,
            listingDetailName: 'listingDetail',
            user: null,
            showOnlyFive: false,
            isLazyLoading: false
        }
    },

    watch: {
        $route() {

            this.loadListings(this.$route.params.categoryId)
        },
        //rangeSelector(newVal) {
        //    var distances = [3, 5, 10, 25, 50, 100, 500]
        //    this.filter.rangeInKm = distances[newVal];
        //},
        //postCode: {
        //    immediate: true,
        //    handler(newVal) {
        //        //console.log(newVal);
        //        this.filter.postcode4 = newVal;
        //        this.searchFilters("LocationFilter.postcode", newVal, undefined, true);
        //    }

        //}
    },

    methods: {
        resetFilters: function () {
            this.filter.rangeInKm = 500;
            this.filters = []
            this.$router.push({
                name: this.$route.name,
                params:
                {

                    'categoryId': typeof (this.$route.params.categoryId) !== 'undefined' ? this.$route.params.categoryId : '',
                    'page': 1,
                    'filters': ''
                }
            })

        },
        applyFilter: function () {
            localStorage.setItem("postcode", this.filter.postcode);
            this.searchFilters("LocationFilter.rangeInKm", this.filter.rangeInKm, undefined, true);
            setTimeout(() => {
                this.searchFilters("LocationFilter.postcode", this.filter.postcode, undefined, true);
            }, 1);
        },
        isFilterApplied: function (field, value) {
            if (typeof (this.$route.params.filters) == 'undefined') { return; }
            var filtersSplit = (this.$route.params.filters).split(";");

            for (let i = 0; i < filtersSplit.length - 1; i++) {

                var a = filtersSplit[i].split(":")[0];
                var b = filtersSplit[i].split(":")[1];

                if (a == field && b == value) {
                    return true;
                }
            }
        },

        searchFilters: function (field, value, e, unique) {
            if (unique) {
                var a = this.filters.find(x => x.field === field);

                if (typeof (a) === 'undefined') {
                    this.filters.push({
                        field: field,
                        value: value,
                    });
                } else {
                    this.filters.find(x => x.field === field).value = value
                }
            } else {
                if (e.target.checked) {
                    var b = this.filters.find(x => x.field === field && x.value === value);
                    if (typeof (b) === 'undefined') {
                        this.filters.push({
                            field: field,
                            value: value,
                        });

                    }
                } else {
                    this.filters.find(x => x.field === field && x.value === value).value = null;
                }
            }

            let filterString = "";
            for (let i = 0; i < this.filters.length; i++) {
                if (this.filters[i].value === null) { continue; }
                filterString = filterString + this.filters[i].field + ":" + this.filters[i].value + ";"
            }
            this.$router.push({
                name: this.$route.name,
                params:
                {
                    'categoryId': typeof (this.$route.params.categoryId) !== 'undefined' ? this.$route.params.categoryId : '',
                    'itemKey': this.$route.params.itemKey,
                },
                query: {
                    'filters': filterString
                }
            })
        },
        getAggregateCount: function (aggregate, aggregateValue) {
            var aggregateResult = this.aggregates.filter(obj => {
                return obj.field === aggregate
            })

            var value = aggregateResult[0].values.filter(obj => {
                return obj.key === aggregateValue
            })
            if (!value.length) {
                return 0;
            }

            return value[0].count;
        },
        showFilters: function () {
            this.showFiltersModal = true;
            var body = document.body
            body.classList.add('modal-open');
        },
        loadPage: function (page) {
            this.$router.push({
                name: this.$route.name,
                params: {
                    'categoryId': typeof (this.$route.params.categoryId) !== 'undefined' ? this.$route.params.categoryId : '',
                    'itemKey': this.$route.params.itemKey
                },
                query: {
                    'page': page,
                    'filters': this.$route.params.filters
                }
            })
            this.pagination.page = page;

            if (document.getElementsByClassName("advertTop").length > 0) {
                document.getElementsByClassName("advertTop")[0].scrollIntoView({ behavior: 'smooth', block: 'start' });
            } else {
                document.getElementById("topNavigation").scrollIntoView({ behavior: 'smooth', block: 'start' });
            }


        },

        loadListings: function (categoryId) {
            categoryId = typeof (categoryId) !== 'undefined' ? categoryId : '';

            var limit = this.showOnlyFive ? 5 : null;
            if (this.limit > 0) {
                limit = this.limit;
            }
            var itemKey = this.$route.params.itemKey;

            var aggregateFilters = [];
            for (let i = 0; i < this.filters.length; i++) {
                aggregateFilters.push({
                    "PropertyName": this.filters[i].field,
                    "Value": this.filters[i].value
                });
            }
            var url = "";
            var params = {};
            if (this.$route.name == 'favorites') {
                url = "users/favorites"
            } else if (this.$route.name == 'listingManagement') {
                url = "users/listings"
            } else if (this.$route.name == 'userDetail') {
                url = "listings?userId=" + this.$route.params.userId
            } else {
                url = "listings"
                params = {
                    categoryPath: categoryId !== '' ? categoryId.join(',') : '',
                    itemKey: itemKey,
                    page: this.pagination.page,
                    AggregateFilters: JSON.stringify(aggregateFilters),
                    LocationFilter: JSON.stringify(this.filter),
                    limit: limit
                }
            }

            httpClient.get('/' + url, {
                params: params
            }).then(result => {
                this.listings = [];
                var data = result.data.data;

                // data.splice(8, 0,
                //     {
                //         "id": 0,
                //         "key": "1",
                //         "imageUrls": []
                //     });
                //data.splice(17, 0,
                //    {
                //        "id": 0,
                //        "key": "2",
                //        "imageUrls": []
                //    });
                //data.splice(26, 0,
                //    {
                //        "id": 0,
                //        "key": "3",
                //        "imageUrls": []
                //    });
                if (typeof (data) === 'undefined') {
                    return;
                }
                this.listings = data;
                this.pagination.total = result.data.info.total;
                this.aggregates = result.data.aggregates;


            });
        },

        handleScroll() {
            if (document.body.scrollTop + window.screen.height < document.documentElement.scrollHeight * 0.9) {

                return;
            }
            if (this.isLazyLoading) {
                return;
            }

            this.isLazyLoading = true;
        }
    },
    mounted() {

        var categoryId = this.$route.params.categoryId

        window.addEventListener('scroll', this.handleScroll);


        if (typeof this.$route.query.page !== 'undefined') {
            this.pagination.page = this.$route.query.page;
        }
        if (this.$route.name == 'item') {
            this.showOnlyFive = true;
            //this.showNavigation = false;
        }

        if (typeof (this.$route.params.filters) !== 'undefined' && this.$route.params.filters.length) {
            var filtersSplit = (this.$route.params.filters).split(";");
            for (let i = 0; i < filtersSplit.length - 1; i++) {
                this.filters.push({
                    field: filtersSplit[i].split(":")[0],
                    value: filtersSplit[i].split(":")[1],
                });
            }
        }
        //this.filter.postcode = localStorage.getItem("postcode");

        this.loadListings(categoryId);


        if (this.$route.name == 'listingManagement') {
            this.showFilterOptions = false;
            this.listingDetailName = 'editListing';
        }

        if (localStorage.getItem("user") !== null) {
            this.user = JSON.parse(localStorage.user);
        }

        console.log(this.width)
    }
}